import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@egaranti/components";

const WarrantyDetails = ({ product, t }) => (
  <div className="mt-8 flex w-full flex-col items-center justify-center border-b px-6 pb-8">
    <div className="flex w-full justify-between">
      <h3 className="text-base font-bold uppercase text-[#677389]">
        {t("productsPage.warrantyPeriod")}
      </h3>
      <div className="flex items-center gap-2">
        <span className="flex shrink-0 items-center gap-1 rounded-xl bg-[#CCDBFA] px-3 py-2 text-center text-xs font-medium text-[#002573]">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.99996 7.66691L7.33329 9.00024L10.3333 6.00024M13.3333 8.00024C13.3333 11.2725 9.76399 13.6525 8.46529 14.4102C8.31769 14.4963 8.24389 14.5393 8.13975 14.5617C8.05892 14.579 7.941 14.579 7.86017 14.5617C7.75602 14.5393 7.68223 14.4963 7.53463 14.4102C6.23593 13.6525 2.66663 11.2725 2.66663 8.00024V4.81197C2.66663 4.27897 2.66663 4.01246 2.7538 3.78338C2.83081 3.581 2.95595 3.40042 3.1184 3.25726C3.30229 3.0952 3.55182 3.00162 4.0509 2.81447L7.62543 1.47402C7.76402 1.42205 7.83332 1.39606 7.90461 1.38576C7.96785 1.37662 8.03207 1.37662 8.0953 1.38576C8.16659 1.39606 8.23589 1.42205 8.37449 1.47402L11.949 2.81447C12.4481 3.00162 12.6976 3.0952 12.8815 3.25726C13.044 3.40042 13.1691 3.581 13.2461 3.78338C13.3333 4.01246 13.3333 4.27897 13.3333 4.81197V8.00024Z"
              stroke="#002573"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {`${product.remainingWarrantyDay} ${t("productsPage.day")}`}
        </span>
      </div>
    </div>
    <div className="mb-8 mt-4 flex w-full flex-col rounded-lg border border-[#E3E7EF]">
      <div className="flex items-center justify-between rounded-lg rounded-b-none border border-[#F9FAFC] bg-white px-4 py-[26px]">
        <h4 className="text-sm text-[#677389] md:text-[16px]">
          {t("productsPage.warrantyPeriod")}
        </h4>
        <span className="flex items-center gap-1 text-right text-sm font-semibold text-[#111729] md:text-[18px]">
          {product?.extendedWarranty?.extendedWarrantyYear && (
            <TooltipProvider delayDuration={10}>
              <Tooltip>
                <TooltipTrigger aria-label="info">
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.99935 10.1667V6.16667M5.99935 8.16667H9.99935M13.3327 8.5C13.3327 11.7723 9.76338 14.1523 8.46468 14.9099C8.31708 14.996 8.24328 15.0391 8.13914 15.0614C8.05831 15.0787 7.94039 15.0787 7.85956 15.0614C7.75541 15.0391 7.68162 14.996 7.53402 14.9099C6.23532 14.1523 2.66602 11.7723 2.66602 8.5V5.31173C2.66602 4.77873 2.66602 4.51222 2.75319 4.28314C2.8302 4.08076 2.95534 3.90018 3.11779 3.75702C3.30168 3.59496 3.55121 3.50138 4.05029 3.31423L7.62482 1.97378C7.76341 1.92181 7.83271 1.89582 7.904 1.88552C7.96724 1.87638 8.03146 1.87638 8.09469 1.88552C8.16598 1.89582 8.23528 1.92181 8.37388 1.97378L11.9484 3.31423C12.4475 3.50138 12.697 3.59496 12.8809 3.75702C13.0434 3.90018 13.1685 4.08076 13.2455 4.28314C13.3327 4.51222 13.3327 4.77873 13.3327 5.31173V8.5Z"
                      stroke="#364153"
                      stroke-width="1.6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </TooltipTrigger>
                <TooltipContent>
                  {t("productsPage.extendedWarranty", {
                    years: product?.extendedWarranty?.extendedWarrantyYear,
                  })}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
          {product.remainingWarrantyDay} {t("productsPage.day")}
        </span>
      </div>
      <div className="flex items-center justify-between rounded-lg rounded-t-none border border-[#F9FAFC] bg-white px-4 py-[26px]">
        <h4 className="text-sm text-[#677389] md:text-[16px]">
          {t("productsPage.brand")}
        </h4>
        <span className="text-right text-sm font-semibold text-[#111729] md:text-[18px]">
          {product.merchantName}
        </span>
      </div>
    </div>
  </div>
);

export default WarrantyDetails;
